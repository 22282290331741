<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·客户详细信息新建联系人弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<!--客户详细信息新建联系人-->
<template>
	<div id="crm-connectInfo">
		<div class="connectInfo">
			<el-dialog :title="cntTitle" class='yourName new-linkMan dialogTwo' size="tiny" :visible.sync="test" :before-close="cntClose" :modal="false" :modal-append-to-body="true" :lock-scroll="true" :close-on-click-modal="false">
				<popupLoading></popupLoading>
				<div class="cnt-tab" style="overflow-y: auto;height: 350px;">
					<table width="100%" cellpadding="0" cellspacing="0" class="myTable">
						<tr v-if="isMainTag">
							<td class="td-title lm">主联系人</td>
							<td class="td-info">
								<el-radio-group v-model="isMain">
									<el-radio :label="1">是</el-radio>
									<el-radio :label="0">否</el-radio>
								</el-radio-group>
							</td>
						</tr>
						<tr>
							<td class="td-title st"><span>姓名</span></td>
							<td class="td-info">
								<input type="text" style="height: 30px;" class="importBox" @blur="linkname_verify()" v-model="link_name" />
								<i class="red">*</i>
								<span v-show="hasVertify" :title="correct?correctTitle:errorTitle" class="rightError" :class="correct?'right-span':'error-span'"></span>
							</td>
						</tr>
						<tr>
							<td class="td-title st"><span>邮箱</span></td>
							<td class="td-info">
								<input type="text" :disabled="canAlert" style="height: 30px;" class="importBox" @blur="email_verify()" v-model="email" />
								<i class="red">*</i>
								
								<div  class="companyLoadDiv">
									<span v-show="hasVertify2" :title="correct2?correctTitle:errorTitle2" class="rightError" :class="correct2?'right-span':'error-span'"></span>
									 <span title="正在验证，请等待" class="companyLoadSpan" v-show="emailLoad">
										<i class="el-icon-loading"></i>
										</span>
								</div>
							</td>
						</tr>
						<tr>
							<td class="td-title st"><span>电话</span></td>
							<td class="td-info">
								<input type="text" style="height: 30px;" class="importBox" v-model="phone" />
								<i v-if="!canAlert" @click="showT = !showT" :class="[showT?'unfold':'fold']" title="多个电话"></i>
							</td>
						</tr>
						<tr v-if="showT">
							<td class="td-title st">
								<span>电话1</span>
							</td>
							<td class="td-info">
								<input type="text" style="height: 30px;" class="importBox" v-model="phone1" />
							</td>
						</tr>
						<tr v-if="showT">
							<td class="td-title st">
								<span>电话2</span>
							</td>
							<td class="td-info">
								<input type="text" style="height: 30px;" class="importBox" v-model="phone2" />
							</td>
						</tr>
						<tr>
							<td class="td-title st"><span>手机</span></td>
							<td class="td-info"><input type="text" style="height: 30px;" class="importBox" v-model="mobile" /></td>
						</tr>
						<tr>
							<td class="td-title st"><span>职位</span></td>
							<td class="td-info"><input type="text" style="height: 30px;" class="importBox" v-model="post" /></td>
						</tr>
						<tr>
							<td class="td-title st"><span>性别</span></td>
							<td class="td-info">
								<el-radio-group v-model="sex">
									<el-radio :label="1">男</el-radio>
									<el-radio :label="0">女</el-radio>
								</el-radio-group>
							</td>
						</tr>
						<tr>
							<td class="td-title st"><span>地址</span></td>
							<td class="td-info"><input type="text" style="height: 30px;" class="importBox" v-model="address" /></td>
						</tr>
						<tr>
							<td class="td-title st"><span>网址</span></td>
							<td class="td-info"><input type="text" style="height: 30px;" class="importBox" v-model="website" /></td>
						</tr>
					</table>
				</div>
				<span slot="footer" class="dialog-footer" v-if="hasButton">
          <div class="btn-box">
            <el-button type="primary" @click="cntConfirm">确 定</el-button>
            <el-button  @click="cntClose">取 消</el-button>
          </div>
        </span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import store from '../../../vuex/store.js'
	import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
	import '../../../vuex/mutations.js';
	import popupLoading from '@/components/publicComponent/popupLoading'
	export default {
		name: "cm-more-info",
		data() {
			return {
				cntTitle: '',//弹窗标题

				oldEmail: '', //编辑前的邮箱
				isMainTag: true, //是否可以更改主次联系人
				showT: true, //是否显示多个电话
				isMain: 0, //是否主联系人,1是,0否
				canAlert: false, //未入库联系人编辑时不可编辑邮箱
				sex: 1, //性别
				create_user_id: 0, //用户id

				test: true,

				hasVertify: false,
				correct: false,
				errorTitle: "",
				link_name: '',

				hasVertify2: false,
				correct2: false,
				errorTitle2: "",
				email: '',
				emailLoad:false,

				correctTitle: "恭喜，输入正确！",

				phone: '',//电话
				phone1: '',//电话1
				phone2: '',//电话2
				mobile: '',//手机
				post: '',//职位
				address: '',//地址
				website: '',//网址
				emailTrue:false,
			}
		},
		props: [
			'linkman_id', //联系人id
			'classify_id', //客户分类id
			'customer_id', //客户id

			'hasButton',
			'connectionTab' //表格内容
		],
		computed: {
			...mapState({
				userinfo: state => state.userinfo,
				userlistall: state => state.userlistall,
				companyparamlist: state => state.companyparamlist,
			}),
		},
		components: {
		popupLoading
	},
		methods: {
			...mapMutations({
				getUserlistAll: 'getUserlistAll',
			}),
			//验证姓名
			linkname_verify() {
				if(this.link_name.trim().length < 2 || this.link_name.trim().length > 50) {
					this.hasVertify = true;
					this.correct = false;
					this.errorTitle = '联系人姓名2~50个字符之间!';
					return;
				}
				this.hasVertify = true;
				this.correct = true;
			},
			//验证邮箱
			email_verify() {
				var mailreg = /^\w+([\.\'-]?[\w+]?)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
				if(mailreg.test(this.email.trim())) {
					if(this.email.trim() != this.oldEmail.trim()) {
						this.hasVertify2 = true;
						this.correct2 = true;
						var lef = this;
						$.ajax({
							type: "POST",
							url: backUrl + "xcus_isCustomerEmailExist2.action",
							dataType: "json",
							cache: false,
							// async: false,
							data: {
								token: token,
								verifystr: lef.email.trim(),
								classify_id: lef.classify_id,
								sysenablerepeat: lef.companyparamlist.param159
							},
							 beforeSend: function() {
								lef.emailLoad = true;
							},
							complete: function() {
								lef.emailLoad = false;
							},
							success: function(msg) {
								if(msg.result == -1) { //不存在
									lef.hasVertify2 = true;
									lef.correct2 = true;
								} else { //存在
									if(lef.userlistall == '') {
										lef.getUserlistAll();
									}
									var username = "其它同事";
									 if(msg.result==0){
									username='我'
								}else if(msg.result>0){
									$.each(lef.userlistall, function(index, user) {
									if(msg.result == user.user_id) {
										username = user.user_name;
										return false;
									}
									});
								}
									lef.hasVertify2 = true;
									lef.correct2 = false;
									let tion=''
							if(msg.type==0){
									tion='正常库'
								}else if(msg.type==1){
									tion='公海'
								}else if(msg.type==2){
									tion='已删除'
								}
								lef.errorTitle2 = '该联系人邮箱对应的客户已存在于 [' + username + '] 的客户中，位置 ['+tion+']';
							lef.$message({
								showClose: true,
								message: '该邮件地址对应的客户已存在于 [' + username + '] 的客户中，位置 ['+tion+']',
								type: 'warning',
								duration: 2000
							});
								}
							},
							error: function(e) {
								lef.hasVertify2 = true;
								lef.correct2 = false;
								lef.errorTitle2 = '检查联系人邮箱重复性网络异常，请刷新界面后重试！';
							}
						});
					} else {
						this.hasVertify2 = true;
						this.correct2 = true;
						this.emailTrue=true
					}
				} else {
					this.hasVertify2 = true;
					this.correct2 = false;
					this.errorTitle2 = '联系人邮箱格式不正确!';
					return;
				}
			},
			//加载联系人信息
			loadLinkManInfo() {
				var lef = this;
				$.ajax({
					type: "POST",
					url: backUrl + "link_findByLinkid.action",
					async: false,
					cache: false,
					dataType: "json",
					data: {
						token: token,
						linkmanid: lef.linkman_id
					},
					success: function(msg) {
						lef.isMain = msg.is_main_linkman;
						lef.customer_id = msg.customer_id;
						lef.create_user_id = msg.create_user_id;
						lef.link_name = msg.full_name;
						lef.post = msg.post;
						lef.oldEmail = msg.email;
						lef.email = msg.email;
						lef.mobile = msg.mobile;
						if(msg.sex == '男') {
							lef.sex = 1
						} else {
							lef.sex = 0;
						}
						lef.address = msg.address;
						lef.website = msg.website;
						if(msg.customer_id > 0) {
							if(msg.is_main_linkman == 1) { //主联系人
								lef.isMainTag = false;
							} else { //次联系人
								lef.isMainTag = true;
							}
						} else {
							lef.isMainTag = false;
						}
						if(msg.customer_id > 0) { //已入库
							lef.canAlert = false;
						} else { //未入库
							lef.canAlert = true;
						}
						lef.showT = lef.canAlert;
						if(msg.phone != null && msg.phone != '') {
							if(msg.phone.split("@G@").length > 2) //3个电话
							{
								lef.phone = msg.phone.split("@G@")[0];
								lef.phone1 = msg.phone.split("@G@")[1];
								lef.phone2 = msg.phone.split("@G@")[2];
							} else if(msg.phone.split("@G@").length > 1) //2个电话
							{
								lef.phone = msg.phone.split("@G@")[0];
								lef.phone1 = msg.phone.split("@G@")[1];
							} else //1个电话
							{
								lef.phone = msg.phone;
							}
						}
					}
				});
			},
			//关闭
			cntClose: function() {
				this.$emit('cntClose', false)
			},
			//确定
			cntConfirm: function() {
				//验证姓名
				this.linkname_verify();
				if(!this.correct) {
					this.$message({
						type: 'warning',
						showClose: true,
						message: this.errorTitle,
						duration: 2000
					});
					return;
				}
				//验证邮箱
				  if(!this.emailTrue){
					this.email_verify()
				}
				if(!this.emailTrue){
					if(this.email==''){
						this.$message({
						type: "warning",
						message:'请输入联系人邮箱！',
						duration: 2000
						});
					}else{
						this.$message({
						type: "warning",
						message:'正在验证联系人邮箱，请稍后操作！',
						duration: 2000
						});
					}
					return;
				}
				if(!this.correct2) {
					this.$message({
						type: 'warning',
						showClose: true,
						message: this.errorTitle2,
						duration: 2000
					});
					return;
				}

				var manyphone = "";
				if(this.showT) { //多电话
					var phone1 = "";
					if(this.phone1.trim() != "" && this.phone1.trim().length != 0) {
						phone1 = this.phone1.trim();
					}
					manyphone = phone1;
					var phone2 = "";
					if(this.phone2.trim() != "" && this.phone2.trim().length != 0) {
						phone2 = this.phone2.trim();
					}
					if(manyphone != "") {
						if(phone2 != "") {
							manyphone += "@G@" + phone2;
						}
					} else {
						manyphone = phone2;
					}
				}
				//有联系电话
				if(this.phone.trim() != "" && this.phone.trim().length != 0) {
					if(manyphone != "") {
						manyphone = this.phone.trim() + "@G@" + manyphone;
					} else {
						manyphone = this.phone.trim();
					}
				}

				var sexStr = "男";
				if(this.sex == 0) {
					sexStr = "女";
				}
				//主电话
				if($.trim(this.phone) != "") {
					if($.trim(this.phone).length > 100) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '电话不能超过100个字符！',
							duration: 2000
						});
						return;
					}
				}

				//电话1
				if($.trim(this.phone1) != "") {
					if($.trim(this.phone1).length > 100) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '电话1不能超过100个字符！',
							duration: 2000
						});
						return;
					}
				}
				//电话2
				if($.trim(this.phone2) != "") {
					if($.trim(this.phone2).length > 100) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '电话2不能超过100个字符！',
							duration: 2000
						});
						return;
					}
				}
				//手机
				if($.trim(this.mobile) != "") {
					if($.trim(this.mobile).length > 50) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '手机不能超过50个字符！',
							duration: 2000
						});
						return;
					}
				}
				//职位
				if($.trim(this.post) != "") {
					if($.trim(this.post).length > 50) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '职位不能超过50个字符！',
							duration: 2000
						});
						return;
					}
				}
				//地址
				if($.trim(this.address) != "") {
					if($.trim(this.address).length > 300) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '地址不能超过300个字符！',
							duration: 2000
						});
						return;
					}
				}
				//网址
				if($.trim(this.website) != "") {
					if($.trim(this.website).length > 100) {
						this.$message({
							type: 'warning',
							showClose: true,
							message: '网址不能超过100个字符！',
							duration: 2000
						});
						return;
					}
				}
				if(this.linkman_id > 0) { //编辑
					var lef = this;
					$.ajax({
						type: "POST",
						url: backUrl + "link_updateLinkman.action",
//						async: false,
						cache: false,
						dataType: "json",
						data: {
							token: token,
							"crmlinkman.customer_id": lef.customer_id,
							"crmlinkman.linkman_id": lef.linkman_id,
							"crmlinkman.create_user_id": lef.create_user_id,
							"crmlinkman.is_main_linkman": lef.isMain,
							"crmlinkman.full_name": lef.link_name,
							"crmlinkman.email": lef.email,
							"manyphone": manyphone,
							"crmlinkman.mobile": lef.mobile,
							"crmlinkman.post": lef.post,
							"crmlinkman.sex": sexStr,
							"crmlinkman.address": lef.address,
							"crmlinkman.website": lef.website,
						},
						beforeSend: function() {
					lef.$store.state.popupLoading = true;
				},
				complete: function() {
					lef.$store.state.popupLoading = false;
				},
						success: function(msg) {
							if(msg == 1) {
								lef.$message({
									showClose: true,
									message: '编辑成功！',
									type: 'success',
									duration: 2000
								});
								lef.$emit('linkmanReturn', lef.isMain);
							} else {
								lef.$message({
									showClose: true,
									message: '编辑失败！',
									type: 'warning',
									duration: 2000
								});
							}
						}
					});
				} else { //新增
					var lef = this;
					$.ajax({
						type: "POST",
						url: backUrl + "link_addCustomerLinkMan.action",
//						async: false,
						cache: false,
						dataType: "json",
						data: {
							token: token,
							customerid: lef.customer_id,
							classify_id: lef.classify_id,
							"crmlinkman.is_main_linkman": lef.isMain,
							"crmlinkman.full_name": lef.link_name,
							"crmlinkman.email": lef.email,
							"manyphone": manyphone,
							"crmlinkman.mobile": lef.mobile,
							"crmlinkman.post": lef.post,
							"crmlinkman.sex": sexStr,
							"crmlinkman.address": lef.address,
							"crmlinkman.website": lef.website,
						},
						beforeSend: function() {
					lef.$store.state.popupLoading = true;
				},
				complete: function() {
					lef.$store.state.popupLoading = false;
				},
						success: function(msg) {
							if(msg == 1) {
								lef.$message({
									showClose: true,
									message: '添加成功！',
									type: 'success',
									duration: 2000
								});
								lef.$emit('linkmanReturn', lef.isMain);
							} else {
								lef.$message({
									showClose: true,
									message: '添加失败！',
									type: 'warning',
									duration: 2000
								});
							}
						}
					});
				}
			},
		},
		mounted() {
			if(this.linkman_id > 0) {
				this.cntTitle = '编辑联系人';
				this.loadLinkManInfo();
			} else {
				this.cntTitle = '新增联系人';
			}

			function clearTransform() {
				$('.el-dialog').css({
					'transform': 'none',
				})
			};
			dialogDrag();
			clearTransform(dialogCenter());
		},
		watch:{
			emailLoad:function(val){
			if(!val){
				this.emailTrue=true
			}else{
				this.emailTrue=false
			}
			}
		}
	}
</script>
<style lang="less">
	#crm-connectInfo {
		.connectInfo {
			.new-linkMan {
				.el-dialog {
					width: 400px;
					height: auto;
				}
			}
		}
	}
</style>
<style src="../../../css/crm/common_layer.css"></style>