<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：公用的所在国家信息弹框
开始时间：2019-9-21
开发人员：吴荣江
最后修改：2019-9-21
备注说明：如需修改请联系开发人员
-->

<template>
  <div style="display:inline-block;">
    <input
      class="custx ac_input countryH"
      v-model="country"
      name="crmcustomer2.country"
      @blur="verifyCountry"
      @focus="focusCountry"
      autocomplete="off"
      @keydown="getInputingValue($event)"
      :title="country"
      :disabled="hasEdit"
      id="os_name"
    />
    <span class="red" v-show="isShow1" title="必填项" style="cursor: pointer;">*</span>
    <span
      style="cursor: pointer;"
      v-show="hasVertify3"
      :title="correct3?correctTitle:errorTitle3"
      class="rightError"
      :class="correct3?'right-span':'error-span'"
    ></span>
    <div
      class="countryBox"
      v-show="countryFocus"
      @mouseleave="leaveCountry"
      @mouseenter="hoverCountryBox"
    >
      <div
        v-for="(countryInfo,cdex) in showCountryList"
        :key="'counrty'+cdex"
        :title="countryInfo.name+' - '+countryInfo.short"
        @click="confirmCountry(countryInfo.name)"
        v-show="showCountryList.length>0"
        class="hoverCountry"
      >
        <span class="countryFlag" :class="countryInfo.class"></span>
        <span class="countName">{{countryInfo.name}} - {{countryInfo.short}}</span>
      </div>
      <div v-if="showCountryList.length==0" class="countryNoData">暂无数据</div>
    </div>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex'
export default {
  name: "allCountry",
  data() {
    return {
      country: "", //当前输入的值
      countryFocus: false, //是否进入国家填写
      countryOldName: "", //在进入所选国家时，获取之前选中的名称
      showCountryList: [], //当前搜索中展示的列表数据
      timer: 0, //当前搜索查询执行的时间
      hoverCountry: false, //当前是否hover在国家选框内
      clickCountry: false, //当前选择的国家是否是从国家选项中选择的
      hasEdit: false, //是否可进行编辑
      isBlur: false, //还在框中
      isShow1: false, //是否是必填项
      hasVertify3: false, //是否正确
      correct3: false,
      correctTitle: "恭喜，输入正确！", //正确时的显示
      errorTitle3: "" //错误时的显示
    };
  },
  methods: {
    ...mapActions([
      'getCountryInfo'
    ]),
    //验证当前输入的国家blur
    verifyCountry() {
      this.isBlur = false;
      //判断当前是否有hover在国家选框中，有则显示否则隐藏
      if (!this.hoverCountry) {
        this.countryFocus = false;
        //判断当前的值是否在国家数组中
        this.getKeyword(this.country);
        if (this.showCountryList.length == 0) {
          if (this.isShow1) {
            this.hasVertify3 = true;
            this.correct3 = false;
          }
          if (
            this.country !== null &&
            this.country !== undefined &&
            this.country != ""
          ) {
            this.$message({
              message: "国家填写错误,所在国家应该从下拉数据中选取！",
              type: "warning",
            });
            this.errorTitle3 = "国家填写内容有误,应该从下拉数据中选取!";
          } else {
            this.errorTitle3 = "国家不能为空!";
          }
          this.country=''
        } else {
          let isKey = false;
          let self = this;
          if (this.country == "") {
            if (this.isShow1) {
              this.correct3 = false;
              this.errorTitle3 = "国家不能为空!";
              return;
            }
          } else {
            $.each(this.showCountryList, function(b, row) {
              if (row.name == self.country) {
                isKey = true;
                return false;
              }
            });
            if (!isKey) {
              this.correct3 = false;
              this.$message({
                message: "国家填写错误,所在国家应该从下拉数据中选取！",
                type: "warning",
              });
              this.country == "";
              if (this.isShow1) {
                this.hasVertify3 = true;
                this.correct3 = false;
                this.errorTitle3 = "国家填写内容有误,应该从下拉数据中选取!";
              }
            } else {
              if (this.isShow1) {
                this.hasVertify3 = true;
                this.correct3 = true;
              }
            }
          }
        }
      }
    },
    //鼠标进入的时候显示弹框的内容
    focusCountry() {
      this.countryOldName = this.country;
      this.countryFocus = true;
      if (this.country == "") {
        this.showCountryList = this.$store.state.countryArr;
      } else {
        this.getKeyword(this.country);
      }
      this.isBlur = true;
    },
    //点击确认时获取当前的城市数据
    confirmCountry(name) {
      this.country = this.commonJs.verifyCountry(name);
      if(this.isShow1){
        this.hasVertify3 = true;
      this.correct3 = true;
      }
      this.countryFocus = false;
      this.clickCountry = true;
    },
    //离开搜索的时候关闭联动弹框
    leaveCountry() {
      if (!this.isBlur) {
        //判断当前的值是否在国家数组中
        this.getKeyword(this.country);
        if (this.showCountryList.length == 0) {
          if (
            this.country !== null &&
            this.country !== undefined &&
            this.country != ""
          ) {
            if(this.isShow1){
               this.hasVertify3 = true;
              this.correct3 = false;
            }
            this.$message({
              message: "国家填写错误,所在国家应该从下拉数据中选取！",
              type: "warning",
            });
            this.errorTitle3 = "国家填写内容有误,应该从下拉数据中选取!";
          } else {
            this.errorTitle3 = "国家不能为空!";
          }
          this.country =''
        }
        this.countryFocus = false;
      }
      this.hoverCountry = false;
      this.clickCountry = false;
    },
    //hover在国家选择上
    hoverCountryBox() {
      this.hoverCountry = true;
    },
    //实时获取当前用户输入的关键字来匹配城市信息
    getInputingValue(e) {
      //判断当前是否是删除键，并且当前的输入文字长度为0
      if (e.keyCode == "8") {
        this.country = "";
        this.showCountryList = this.$store.state.countryArr;
      }
    },
    getKeyword(keyword) {
      if (keyword !== null && keyword !== undefined && keyword !== "") {
        keyword = keyword.toLowerCase();
      } else {
        if (this.country == "") {
          if(this.isShow1){
             this.hasVertify3 = true;
          this.correct3 = false;
          this.errorTitle3 = "国家不能为空!";
          }
        }
        return;
      }
      const result = [];
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        let searchCur = this.$store.state.countryArr;
        for (let j = 0, len = searchCur.length; j < len; j++) {
          let name = searchCur[j].name.toLowerCase();
          if (
            name.indexOf(keyword) > -1 ||
            searchCur[j].short.indexOf(keyword) > -1
          ) {
            result.push(searchCur[j]);
          }
          this.showCountryList = result;
        }
      }, 100);
    },
    //处理必填项
    handleNecessary() {
      //加载必填项信息
      if (this.$store.state.necessaryitemall == "") {
        this.$store.commit("getNecessaryitemAll");
      }
      if (this.$store.state.necessaryitemall.length == 0) {
        this.isShow1 = false;
      } else {
        let self = this;
        $.each(this.$store.state.necessaryitemall, function(index, row) {
          if (row.necessary_item.trim() == "所在国家") {
            self.isShow1 = true;
            return false;
          }
        });
      }
    }
  },
  computed: {
    ...mapState({
      countryArr:state=>state.countryArr
    }),
    curEdit() {
      return this.$store.state.curCountryEdit;
    },
    curCountryValue() {
      return this.$store.state.curCountry;
    }
  },
  mounted() {
    if (this.$store.state.fromCountryEdit) {
      this.hasEdit = true;
    } else {
      this.hasEdit = false;
    }
    this.$store.commit("FROM_COUNTRY_EDIT", false);
    window.onload = function() {
      $("#os_name").val("");
    };
    this.country = this.$store.state.curCountry;
    this.handleNecessary();
    //加载国家数据
    if(this.countryArr==''){
      this.getCountryInfo()
    }
  },
  watch: {
    country: function(val) {
      if (val !== null && val !== undefined && val != "") {
        this.getKeyword(val);
      } else {
        this.showCountryList = this.$store.state.countryArr;
      }
    },
    curEdit: function(val) {
      this.hasEdit = !val;
    },
    curCountryValue: function(val) {
      this.country = val;
    }
  }
};
</script>
<style src="@/css/common.css">
</style>