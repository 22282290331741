<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·快捷写信页面
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <!--快捷写信页面-->
  <div id="writeMessage">
    <div>
      <div class="writeMessage-box">
        <el-dialog
          title="快捷写信"
          class="yourName messageTitle dialogTwo"
          size="tiny"
          :visible.sync="test"
          :before-close="closeMessage"
          :modal="false"
          :modal-append-to-body="true"
          :lock-scroll="true"
          :close-on-click-modal="false"
        >
          <!--此处添加你的弹框内容-->
          <div
            class="message-content"
            v-loading="sendletterLoading"
            element-loading-text="正在发信，请稍候..."
          >
            <div class="message-info-title">
              <div class="title-sendMessage">
                <span class="m-title">发件人</span>
                <div class="title-box">
                  <select v-model="defaultId">
                    <option
                      :value="option.id"
                      v-for="(option,index ) in sendList"
                      :title="option.sender"
                      :key="index"
                    >{{option.sender1==''?option.sender:option.sender1}}</option>
                  </select>
                </div>
              </div>
              <div class="title-getMessage">
                <span class="m-title">收件人</span>
                <div class="title-box">
                  <input type="text" :value="receiver" readonly="readonly" class="myEmail" />
                </div>
              </div>
              <div class="title-theme">
                <span class="m-title">主题</span>
                <div class="title-box">
                  <input type="text" v-model="myTheme" />
                </div>
              </div>
            </div>
            <div>
              <textarea id="mailBodyId" name="mailBodyId" style="width:100%;height:315px;"></textarea>
            </div>
          </div>
          <!--底部确认取消按钮-->
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="sendMessage">发 送</el-button>
            <el-button @click="writeMessage">切换到完整写信</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../../../vuex/store.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import "../../../vuex/mutations.js";
var editor = "";
export default {
  name: "writeMessage",
  data() {
    return {
      defaultId: 0, //默认帐号
      sendList: [], //发件人集合
      receiver: "", //收件人
      defaultMsg: "",
      myTheme: "", //主题
      test: true,
	  sendletterLoading: false,
	  poporimapType:0,//
	  email:'',//邮箱
    };
  },
  components: {},
  props: [
    "cusorlink",
    "id", //联系人id
    "myEmail",
    "cusId" //客户id
  ],
  computed: {
    ...mapState({
      userinfo: state => state.userinfo,
      mailaccountlist: state => state.mailaccountlist,
      userparamlist: state => state.userparamlist,
      companyparamlist: state => state.companyparamlist,
      customerEmailInfo: state => state.customerEmailInfo
    })
  },
  methods: {
    ...mapMutations({
      fun_findAccountlist: "fun_findAccountlist",
      updateFollowcount  :  "updateFollowcount",
    }),
    ...mapActions({
      update_linkmanMailCount: "update_linkmanMailCount",
      updateCusLastContactTime: "updateCusLastContactTime",
	  updateCusSendMailCount: "updateCusSendMailCount",
	  getAccountSent:'getAccountSent',
	   getComsterInfo: "getComsterInfo"
    }),
    //发送邮件
    sendMessage() {
      //判断是否选择发送人
      if (this.defaultId == 0) {
        this.$message({
          showClose: true,
          message: "请选择发送人！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      //判断邮件主题是否为空
      if (this.myTheme.trim() == "") {
        this.$message({
          showClose: true,
          message: "请输入邮件主题！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      //邮件主题不能超过300个字符
      if ($.trim(this.myTheme).length > 1000) {
        this.$message({
          showClose: true,
          message: "邮件主题不能超过300个字符！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      //邮件内容不能超过为空
      if (editor.html() == "") {
        this.$message({
          showClose: true,
          message: "请输入邮件内容！",
          type: "warning",
          duration: 2000
        });
        return;
      }
      var savepath = this.userparamlist.param111;
      if (savepath == "" || savepath == null) {
        savepath = this.companyparamlist.param113;
      }
	  var lef = this;
	   
      $.ajax({
        type: "post",
        url: backUrl + "cus_cusSendEMail.action",
        dataType: "json",
        async: true,
        cache: false,
        data: {
          token: token,
          receivers: lef.receiver,
          title: lef.myTheme.trim(),
          content: editor.html(),
          savepath: savepath,
          accountid: lef.defaultId,
          is_cus: lef.cusorlink
        },
        beforeSend: function() {
          lef.sendletterLoading = true;
        },
        complete: function() {
          lef.sendletterLoading = false;
        },
        success: function(msg) {
          if (msg.result > 0) {
            lef.$message({
              showClose: true,
              message: "发送成功！",
              type: "success",
              duration: 2000
            });
            $.ajax({
              type: "post",
              url: backUrl + "link_findInfoByEmail.action",
              dataType: "json",
              cache: false,
              data: {
                token: token,
                email: lef.myEmail,
                full_name: ""
              },
              success: function(msg1) {
                // 统计跟进记录
                if(msg1.cusid != 0){
                  lef.updateFollowcount(msg1.cusid);
                }
                $.ajax({
                  type: "post",
                  url: backUrl + "link_updateLinkManUsed.action",
                  dataType: "json",
                  cache: false,
                  data: {
                    token: token,
                    linkmanid: msg1.linkid
                  },
                  success: function(msg2) {}
                });
                lef.update_linkmanMailCount({ linkmanid: msg1.linkid });
                if (lef.cusorlink == 0) {
                  //0:联系人 1:客户
                  lef.updateCusLastContactTime(lef.cusId);
                  lef.updateCusSendMailCount(lef.cusId);
                } else {
                  lef.updateCusLastContactTime(lef.id);
                  lef.updateCusSendMailCount(lef.id);
                }
              }
            });
            //判断当前是否客户
           if(lef.cusorlink==1){
			    lef.getComsterInfo(lef.cusId);
            let linkmanid = 0;
            let time = "";
            $.each(lef.customerEmailInfo, (j, jelem) => {
              if (jelem.is_main_linkman == 1) {
                linkmanid = jelem.linkman_id;
                return false;
              }
			});
            lef.getAccountSent({
              customer_id: lef.cusId,
			  linkmanid:linkmanid,
				mail_uid:msg.resultstr2,
				receive_mail:lef.myEmail,
				send_time:lef.commonJs.time(),
				account_id:lef.defaultId,
				mail_account:lef.email,
				poporimap:lef.poporimapType,
            });
		   }
            lef.$emit("closeMessage");
          } else {
            lef.$message({
              showClose: true,
              message: "发送失败！",
              type: "warning",
              duration: 2000
            });
          }
        }
      });
    },
    //快捷写信弹窗
    closeMessage() {
      this.$emit("closeMessage");
    },
    //切换到完整写信
    writeMessage() {
      this.$emit("writeMessage", this.receiver);
    },
    //获取收件人
    getReceiver() {
      var lef = this;
      let id = 0;
      //判断当前收件人id
      if (lef.cusorlink == 1) {
        id = lef.cusId;
      } else {
        id = lef.id;
      }
      $.ajax({
        type: "POST",
        url: backUrl + "link_findFullNameById.action",
        async: false,
        cache: false,
        dataType: "json",
        data: {
          token: token,
          linkmanid: id,
          cusorlink: lef.cusorlink
        },
        success: function(msg) {
          if (msg != null && msg != "") {
            lef.receiver = msg + "<" + lef.myEmail + ">";
          } else {
            lef.receiver = lef.myEmail.split("@")[0] + "<" + lef.myEmail + ">";
          }
        }
      });
    },
    //获取默认签名
    getDefaultSignature() {
      var lef = this;
      $.ajax({
        type: "POST",
        url: backUrl + "xsign_findbyUserList.action",
        dataType: "json",
        async: false,
        cache: false,
        data: {
          token: token,
          upuserid: lef.userinfo.user_id
        },
        success: function(msg) {
          if (msg != null) {
            setTimeout(function() {
              editor.html(
                "<br><br><br><br><br><br>--------------------------------------------------------" +
                  msg.context.replace(
                    'src="/userimg/signed/',
                    'src="' + backUrl + "/userimg/signed/"
                  ) +
                  "<br>"
              );
            }, 0);
          }
        }
      });
    }
  },
  mounted() {
    //初始化编辑器
    setTimeout(function() {
      editor = KindEditor.create('textarea[name="mailBodyId"]', {
        themeType: "simple",
        items: [
          "source",
          "|",
          "fontname",
          "fontsize",
          "|",
          "forecolor",
          "hilitecolor",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "lineheight",
          "removeformat",
          "|",
          "link",
          "unlink"
        ],
        allowFileManager: true,
        filterMode: false,
        allowImageUpload: true,
        autoHeightMode: false,
        resizeType: 0,
        afterCreate: function() {
          this.loadPlugin("autoheight");
        },
        afterBlur: function() {
          this.sync();
        }
      });
    }, 0);
    //查询当前用户所有邮箱帐号
    if (this.mailaccountlist == "") {
      this.fun_findAccountlist();
    }
	var lef = this;
    $.each(lef.mailaccountlist, function(index, row) {
      var sender = row.mail_account.split("@")[0];
      var sender1 = "";
      if (row.sender != null && row.sender != "") {
        sender1 = sender + "<" + row.mail_account + ">";
        if (sender1.length > 37) {
          sender1 = sender1.substring(0, 37) + "...";
        }
      }
      lef.sendList.push({
        sender: sender + "<" + row.mail_account + ">",
        sender1: sender1,
        id: row.account_id
      });
      if (row.defalut_show == 1) {
		lef.defaultId = row.account_id;
		lef.poporimapType=row.poporimap
		lef.email=row.mail_account
      } else if (row.is_default == 0) {
		lef.defaultId = row.account_id;
		lef.poporimapType=row.poporimap
		lef.email=row.mail_account
	  }
    });
    lef.getReceiver(); //获取收件人
    lef.getDefaultSignature(); //获取默认签名

    function clearTransform() {
      $(".el-dialog").css({
        transform: "none"
      });
    }
    dialogDrag();
    clearTransform(dialogCenter());
    console.log(this.cusId);
    console.log(this.id);
    console.log(this.cusorlink);
    console.log(this.myEmail);
  }
};
</script>
<style lang="less">
#writeMessage {
  .dialog-masks {
    z-index: 2001;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    overflow: auto;
    margin: 0;
  }
  .writeMessage-box {
    position: fixed;
    z-index: 2002;
    .message-content {
      width: 100%;
      .message-info-title {
        .m-title {
          float: left;
          margin-left: 20px;
          line-height: 30px;
        }
        .title-box {
          margin-left: 70px;
          height: 30px;
          line-height: 30px;
          margin-bottom: 7px;
          select {
            display: inline-block;
            width: 250px;
            height: 100%;
          }
          input {
            border: 1px solid #ddd;
            display: inline-block;
            width: 100%;
            height: 100%;
            text-indent: 5px;
          }
          .myEmail {
            color: #444;
          }
        }
      }
    }
  }
  /*头部样式*/
  .yourName .el-dialog__header {
    line-height: 0;
    background-color: #20a0ff;
    padding: 15px 20px;
    text-align: left;
  }
  /*字体颜色*/
  .yourName .el-dialog__title,
  .yourName .el-dialog__headerbtn .el-dialog__close,
  .yourName .el-dialog__headerbtn .el-dialog__close:hover {
    color: #fff;
  }
  /*弹框内容padding*/
  .yourName .el-dialog__body {
    padding: 20px 10px 10px;
  }
  /*设置所需的宽度*/
  .yourName .el-dialog {
    width: 800px;
    height: 530px;
  }
  /*注意：弹框内容样式前,记得外部div类名，以免类名冲突*/
  .yourName .content {
  }
  .ke-container-simple .ke-statusbar {
    background: transparent;
    height: 1px;
  }
}
</style>